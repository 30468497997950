
    import { defineComponent, onMounted} from "vue";
    //import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
    import chartEditForm from "@/components/ChartEditForm.vue";
    import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
    //import { MenuComponent } from "@/assets/ts/components";
    //import masterstable from "@/components/MastersTable.vue"

    export default defineComponent({
        name: "chart-edit-page",
        components: {
            chartEditForm,

        },
        setup() {
            onMounted(() => {
                //setCurrentPageBreadcrumbs("Charts", ["Widgets"]);
                setCurrentPageTitle("Edit Chart");
                //MenuComponent.reinitialization();
            });
        },
    });
