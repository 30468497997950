
import {
  defineComponent,
  ref,
  toRefs,
  watch,
  PropType,
  EmitsOptions,
  onMounted,
  computed,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
//import { Address } from "@/models/DashboardModel";
import * as Dash from "@/models/DashboardModel";
import { useI18n } from "vue-i18n";
import BnPHelpers from "@/assets/ts/_utils/BnPHelpers";

export default defineComponent({
  name: "command-edit-form",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    commandId: {
      required: false,
      type: Number,
    },
    initCommandEditForm: {
      required: true,
      type: Number,
    },

    chartId: {
      required: true,
      type: String,
    },
    chartTypeId: {
      required: true,
      type: String,
    },

    userSlaves: {
      required: true,
      type: Object as PropType<Mast.Slave[]>,
    },
  },
  emits: ["commandUpdated", "cancelCommandEdit"],

  setup(props, context) {
    //const selectedSlaveId = ref(0);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalEditCommandRef = ref<null | HTMLElement>(null);

    const addressesSelectedReadSlave = ref<Mast.Address[]>([{} as Mast.Address]);
    const addressesSelectedWriteSlave = ref<Mast.Address[]>([{} as Mast.Address]);

    const router = useRouter();
    const { t } = useI18n();

    const isWriteable = computed(() => {
      
      if(commandTypes.value.length == 0)
        return false;

        if(newCommandData.value==null)
        return false;

          
        let  selected  = commandTypes.value.filter( (a) => a.id ==newCommandData.value.commandTypeId );
        if(selected.length==0)
          return false;

        return selected[0].int == 1 || selected[0].int==3 || selected[0].int==5;
    });

    const getEmptyCommand = () => {
      return {
        id: 0,
        chartId :+props.chartId,
        displayName: "Command 1",
        commandType: {} as Mast.GenericOption,
        commandTypeId: 1,
        width: 5,
        writeToAddressId: 123,
        readFromAddressId: 321,
        writeToAddress: { slaveId: 0 } as Mast.Address,
        readFromAddress: { slaveId: 0 } as Mast.Address,
      } as Mast.Command;
    };
    const newCommandData = ref<Mast.Command>(getEmptyCommand());
    const commandTypes = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);
    const writableComandTypes = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);
    //newCommandData.value = getEmptySerie();
    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      addressReadId: Yup.number().min(1).required().label("Address to Read"),
      addressWriteId: Yup.number().min(1).required().label("Address to Write"),
      commandType: Yup.number().required().label("Commandtype"),
      width: Yup.number().required().label("Width"),
      //color: Yup.number().required().label("Color"),
    });

    const { initCommandEditForm } = toRefs(props);


    const addressChange = (e) => {
      console.log(e);
      //
      // if (e.target.options.selectedIndex > -1) {
      //   newCommandData.value.displayName =
      //     e.target.options[e.target.options.selectedIndex].text;
      //   newCommandData.value.width = 1;
      // }
    };

    const slaveChange = () => {
      // newCommandData.value.displayName = "";
      // newCommandData.value.width = 1;
    };



    const slaveWriteChanged = ()=>{
        const postData = {
        searchString: "",
        parentIds: [newCommandData.value.writeToAddress.slaveId],
        pageSize: 10000,
        pageNumber: "",
      } as any;


         ApiService.post("/device/addresses", postData)
        .then(({ data }) => {
          console.log(data.results);
          addressesSelectedWriteSlave.value = data.results;
        })
        .catch(({ response }) => {
          console.log("Fout getaddresses");
        });

    };


    const slaveReadChanged = ()=>{
        const postData = {
        searchString: "",
        parentIds: [newCommandData.value.readFromAddress.slaveId],
        pageSize: 10000,
        pageNumber: "",
      } as any;


         ApiService.post("/device/addresses", postData)
        .then(({ data }) => {
          console.log('dataResults');
          console.log(data.results);
          console.log('end dataResults');
          addressesSelectedReadSlave.value = data.results;
          //return data.results;
        })
        .catch(({ response }) => {
          console.log("Fout getaddresses");
        });

    };

     const getAddresses =  ( slaveId , writable  ) => {
        const postData = {
        searchString: "",
        parentIds: [slaveId],
        pageSize: 10000,
        pageNumber: "",
      } as any;

        return ApiService.post("/device/addresses", postData)
        .then(({ data }) => {
          console.log('dataResults');
          console.log(data.results);
          console.log('end dataResults');
          //addressesSelectedReadSlave.value = data.results;
          return data.results;
        })
        .catch(({ response }) => {
          console.log("Fout getaddresses");
        });
    }

    const submit = () => {
      console.log("Submit");
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.post("/dashboard/commandupdate", newCommandData.value as any)
        .then(({ data }) => {
          console.log("submit Commandresponse data:"); //todo geert
          console.log(data); //todo geert
          // router.push({ name: "editschart",params: {slaveId:data.slaveId} });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          context.emit("commandUpdated");
          hideModal(modalEditCommandRef.value);
        })
        .catch(({ response }) => {
          console.log("Fout CommandUpdate");
        });
    };

    const getCommandById = (id) => {
      if (!(id > 0)) {
        newCommandData.value = getEmptyCommand();

        console.log("newCommandData");
        console.log(newCommandData.value);
        return;
      }

      ApiService.get2("/dashboard/commandget?id=" + id)
        .then(({ data }) => {
          console.log("getCommand response data:"); //todo geert
          console.log(data); //todo geert

          data.writeToAddress = { slaveId: getSlaveByAddress(data.writeToAddressId) };  //todo dit kan Dolf ook meesturen?
          data.readFromAddress = { slaveId: getSlaveByAddress(data.readFromAddressId) };
          console.log(data);
          newCommandData.value = data as Mast.Command;

          slaveWriteChanged();
          slaveReadChanged();

        })
        .catch(({ response }) => {
          console.log("Fout getCommandById");
        });
    };

    // Todo Zoek slaveId op obv addressId omdat SlaveId niet in address zit.
    const getSlaveByAddress = (id) => {
     let curSlaveId = 0;
      props.userSlaves.forEach((slave) => {
        slave.addresses.forEach((address) => {
          if (address.id == id) curSlaveId = address.slaveId;
        });
      });
     
     return curSlaveId;
    };

    watch(initCommandEditForm, () => {
      console.log("CommandId: " + props.commandId);
      getCommandById(props.commandId);
    });

    onMounted(() => {
      BnPHelpers.getOptions("/template/commandtypes", commandTypes).then(() => {
        console.log("commandTypes");
      });

    });

    return {
      addressesSelectedWriteSlave,
      addressesSelectedReadSlave,
      slaveChange,
      //selectedSlaveId,
      newCommandData,
      validationSchema,
      submit,
      submitButtonRef,
      modalEditCommandRef,
      addressChange,
      commandTypes,
      t,
      isWriteable,
      slaveReadChanged,
      slaveWriteChanged
    };
  },
});
