
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
//import  Master from "@/models/MasterModel"
import * as Mast from "@/models/MasterModel";
import { useRouter } from "vue-router";
import * as Dash from "@/models/DashboardModel";
import serieEditForm from "@/components/SerieEditForm.vue";
import { AnyObject } from "yup/lib/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "series-table",
  components: {
    serieEditForm,
  },
  props: {
    widgetClasses: String,
    chartId: String,
    chartTypeId: String,
  },
  setup(props) {
    const editform1 = ref<InstanceType<typeof serieEditForm>>();
    const router = useRouter();
    const { t} = useI18n();
    var serieToEdit = ref<number>(0);
    const series = ref<Dash.Serie[]>([] as Dash.Serie[]);
    const userAdresses = ref<Mast.Address[]>([] as Mast.Address[]);
    const userSlaves = ref<Mast.Slave[]>([] as Mast.Slave[]);
    const initSerieEditForm = ref<number>(0);
    onMounted(() => {
      getSlaves();
      getSeries();
    });

    const getSeries = () => {
      const postData = {
        //todo geert  tijdelijke params
        searchString: "",
        parentIds: [props.chartId],
        pageSize: 10000,
        pageNumber: "",
      } as any;
      ApiService.post("/dashboard/series", postData)
        .then(({ data }) => {
          console.log("getSeries response data:"); //todo geert
          console.log({ ...data }); //todo geert
          series.value = data.results;
        })
        .catch(({ response }) => {
          console.log("Fout getSeries");
        });
    };

    const getSlaves = () => {
      const postData = {
        //todo geert  tijdelijke params
        pageSize: 10000,
      } as any;
      
        ApiService.post("/device/slaves", postData)
        .then(({ data }) => {
          console.log("getSlaves response data:"); //todo geert
          console.log({ ...data }); //todo geert
          userSlaves.value = data.results;
          console.log("Slaves:")
          console.log(data)
        })
        .catch(({ response }) => {
          console.log("Fout getSlaves");
        });
    };

    const clickNewSerie = () => {
      console.log("goNewSerie");
      serieToEdit.value = 0;
      initSerieEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
      //router.push({ name: "editserie" ,params: {chartId:props.chartId}});
      //showSerieEditForm.value = true;
    };

    const clickEditSerie = (data) => {
      console.log("goEditSerie");
      console.log(data);
      //router.push({ name: "editserie",params: {chartId:props.chartId,serieId:data.id}});
      serieToEdit.value = data.id;
      initSerieEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
    };

    const cancelSerieEdit = () => {
      console.log("cancelEditSerie");
      //router.push({ name: "editserie",params: {chartId:props.chartId,serieId:data.id}});
      //showSerieEditForm.value = false;
      serieToEdit.value = 0;
    };

    const serieUpdated = () => {
      console.log("serieUpdated");
      //router.push({ name: "editserie",params: {chartId:props.chartId,serieId:data.id}});
      //showSerieEditForm.value = false;
      serieToEdit.value = 0;
      getSeries();
    };
    const clickDeleteSerie = (data) => {
      console.log("deleteSerie:");
      console.log(data);

      ApiService.post("/dashboard/seriedelete", data as any)
        .then(({ data }) => {
          console.log("delete serie response data:"); //todo geert
          console.log({ ...data }); //todo geert
          getSeries();
        })
        .catch(({ response }) => {
          console.log("Fout SerieDelete");
        });
    };

    return {
      series,
      userSlaves,
      getSeries,
      clickNewSerie,
      clickEditSerie,
      clickDeleteSerie,
      serieToEdit,
      cancelSerieEdit,
      initSerieEditForm,
      serieUpdated,
      t,
    };
  },
});
