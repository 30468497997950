
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
//import  Master from "@/models/MasterModel"
import * as Mast from "@/models/MasterModel";
import { useRouter } from "vue-router";
import * as Dash from "@/models/DashboardModel";
import commandEditForm from "@/components/CommandEditForm.vue";
import { AnyObject } from "yup/lib/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "commands-table",
  components: {
    commandEditForm,
  },
  props: {
    widgetClasses: String,
    chartId: String,
    chartTypeId: String,
  },
  setup(props) {
    //const editform1 = ref<InstanceType<typeof serieEditForm>>();
    const router = useRouter();
    const { t} = useI18n();
    var commandToEdit = ref<number>(0);
    const commands = ref<Mast.Command[]>([] as Mast.Command[]);
    const userAdresses = ref<Mast.Address[]>([] as Mast.Address[]);
    const userSlaves = ref<Mast.Slave[]>([] as Mast.Slave[]);
    const initCommandEditForm = ref<number>(0);
    

    const getCommands = () => {
      // commands.value = [{
      //   id: 121,
      //   displayName: "Command 1",
      //   commandType: {} as Mast.GenericOption,
      //   commandTypeId: 1,
      //   width: 5,
      //   writeToAddressId: 123,
      //   readFromAddressId: 321,
      //   writeToAddress: {} as Mast.Address,
      //   readFromAddress: {} as Mast.Address,
      // }]; return;
      const postData = {
        //todo geert  tijdelijke params
        searchString: "",
        parentIds: [props.chartId],
        pageSize: 10000,
        pageNumber: "",
      } as any;
      ApiService.post("/dashboard/commands", postData)
        .then(({ data }) => {
          console.log("getCommands response data:"); //todo geert
          console.log({ ...data }); //todo geert
          commands.value = data.results;
        })
        .catch(({ response }) => {
          console.log("Fout getCommands");
        });
    };

    const getSlaves = () => {
      const postData = {
        //todo geert  tijdelijke params
        pageSize: 10000,
      } as any;
      
        ApiService.post("/device/slaves", postData)
        .then(({ data }) => {
          console.log("getSlaves response data:"); //todo geert
          console.log({ ...data }); //todo geert
          userSlaves.value = data.results;
          console.log("Slaves:")
          console.log(data)
        })
        .catch(({ response }) => {
          console.log("Fout getSlaves");
        });
    };

    const clickNewCommand = () => {
      console.log("goNewclickNewCommand");
      commandToEdit.value = 0;
      initCommandEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
      
    };

    const clickEditCommand = (data) => {
      console.log("goEditCommand");
      console.log(data);
      commandToEdit.value = data.id;
      initCommandEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
    };

    const cancelCommandEdit = () => {
      console.log("cancelEditCommand");
      commandToEdit.value = 0;
    };

    const commandUpdated = () => {
      console.log("CommandUpdated");
      commandToEdit.value = 0;
      getCommands();
    };
    const clickDeleteCommand = (data) => {
      console.log("deleteCommand:");
      console.log(data);

      ApiService.post("/dashboard/commanddelete", data as any)
        .then(({ data }) => {
          console.log("delete Command response data:"); //todo geert
          console.log({ ...data }); //todo geert
          getCommands();
        })
        .catch(({ response }) => {
          console.log("Fout CommandDelete");
        });
    };

    onMounted(() => {
      getSlaves();
      getCommands();
    });

    return {
      commands,
      userSlaves,
      getCommands,
      clickNewCommand,
      clickEditCommand,
      clickDeleteCommand,
      commandToEdit,
      cancelCommandEdit,
      initCommandEditForm,
      commandUpdated,
      t,
    };
  },
});
