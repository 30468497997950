
import {
  defineComponent,
  ref,
  toRefs,
  watch,
  PropType,
  EmitsOptions,
  onMounted,
  computed,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
//import { Address } from "@/models/DashboardModel";
import * as Dash from "@/models/DashboardModel";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "serie-edit-form",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    serieId: {
      required: false,
      type: Number,
    },
    initSerieEditForm: {
      required: true,
      type: Number,
    },

    chartId: {
      required: true,
      type: String,
    },
    chartTypeId: {
      required: true,
      type: String,
    },

    userSlaves: {
      required: true,
      type: Object as PropType<Mast.Slave[]>,
    },
  },
  emits: ["serieUpdated", "cancelSerieEdit"],

  setup(props, context) {
    const selectedSlaveId = ref(0);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalEditSerieRef = ref<null | HTMLElement>(null);
    const router = useRouter();
    const { t} = useI18n();
   

    const getEmptySerie = () => {
      return {
        id: 0,
        name: "",
        chartId: +props.chartId,
        unit: "",
        multiplier: 1,
        color: 0,
        addressId: 0,
        address: { slaveId: 0 } as Mast.Address,
        isXaxis: false,
        serieRanges: [],
        decimals: 0,
        data: [],
        valueToUse: 3,
        valueOperator: 0,
        valueAbs: 0,
      } as Dash.Serie;
    };
    const newSerieData = ref<Dash.Serie>(getEmptySerie());
    //newSerieData.value = getEmptySerie();
    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      addressId: Yup.number().min(1).required().label("Address"),
      multiplier: Yup.number().required().label("Multiplier"),
      operator: Yup.number().label("Expression"),
      //dataValue: Yup.number().required().label("DataValue"),
      valueToUse: Yup.number().label("ValueToUse"),
      valueOperator: Yup.number().required().label("ValueOperator"),
      unit: Yup.string().required().label("Unit"),
      //color: Yup.number().required().label("Color"),
    });

    const { initSerieEditForm } = toRefs(props);

    const addressChange = (e) => {
      console.log(e);
      //
      if (e.target.options.selectedIndex > -1) {
        newSerieData.value.name =
          e.target.options[e.target.options.selectedIndex].text;
        newSerieData.value.unit =
          addressesSelectedSlave.value[e.target.options.selectedIndex].unit;

        newSerieData.value.decimals = 0;
        newSerieData.value.valueToUse = 3;
        newSerieData.value.valueOperator = 3;
        newSerieData.value.valueAbs = 0;
        newSerieData.value.multiplier = 1;
      }
    };

    const slaveChange = () => {
      newSerieData.value.addressId = 0;

      newSerieData.value.name = "";
      newSerieData.value.unit = "";
      newSerieData.value.decimals = 0;
      newSerieData.value.valueToUse = 3;
      newSerieData.value.valueOperator = 3;
      newSerieData.value.valueAbs = 0;
      newSerieData.value.multiplier = 1;
    };

    const addressesSelectedSlave = computed(() => {
      let tmpSlaves = props.userSlaves.filter(
        (slave) => slave.id == newSerieData.value.address.slaveId
      );
      if (tmpSlaves.length > 0) {
        console.log("returning addresses");
        console.log(tmpSlaves[0].addresses);
        return tmpSlaves[0].addresses;
      } else {
        console.log("returning addresses empty");
        return [];
      }
    });

    const submit = () => {
      console.log("Submit");
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.post("/dashboard/serieupdate", newSerieData.value as any)
        .then(({ data }) => {
          console.log("submit Serieresponse data:"); //todo geert
          console.log({ ...data }); //todo geert
          // router.push({ name: "editschart",params: {slaveId:data.slaveId} });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          context.emit("serieUpdated");
          hideModal(modalEditSerieRef.value);
        })
        .catch(({ response }) => {
          console.log("Fout SerieUpdate");
        });
    };

    const getSerieById = (id) => {
      if (!(id > 0)) {
        newSerieData.value = getEmptySerie();

        console.log("newSerieData");
        console.log(newSerieData.value);
        return;
      }

      ApiService.get2("/dashboard/serieget?id=" + id)
        .then(({ data }) => {
          console.log("getserie response data:"); //todo geert
          console.log({ ...data }); //todo geert

          data.address = { slaveId: getSlaveByAddress(data.addressId) };
          console.log(data.slaveId);
          newSerieData.value = data as Dash.Serie;
        })
        .catch(({ response }) => {
          console.log("Fout getSerieById");
        });
    };

    // Todo Zoek slaveId op obv addressId omdat SlaveId niet in address zit.
    const getSlaveByAddress = (id) => {
      let curSlaveId = 0;
      props.userSlaves.forEach((slave) => {
        slave.addresses.forEach((address) => {
          if (address.id == id) curSlaveId = address.slaveId;
        });
      });
      console.log("curSlaveId");
      console.log(curSlaveId);
      return curSlaveId;
    };
    //watch(serieId, getSerieById)
    watch(initSerieEditForm, () => {
      console.log("serieId: " + props.serieId);
      getSerieById(props.serieId);
    });

    return {
      addressesSelectedSlave,
      slaveChange,
      selectedSlaveId,
      newSerieData,
      validationSchema,
      submit,
      submitButtonRef,
      modalEditSerieRef,
      addressChange,
      t,
    };
  },
});
