
import { defineComponent, ref, PropType } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import Master from "@/models/MasterModel";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import seriesTable from "@/components/SeriesTable.vue";
import commandsTable from "@/components/CommandsTable.vue";
import * as Dash from "@/models/DashboardModel";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "chart-edit-form",
  components: {
    ErrorMessage,
    Field,
    Form,
    seriesTable,
    commandsTable,
  },
  props: {
    chartId: {
      required: false,
      type: String,
    },
  },
  setup(props) {
    const router = useRouter();
    const { t} = useI18n();
    
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);
    console.log("props in Chart");
    console.log(props.chartId);
    var wholeChartData = null;
    var newChartData = ref<Dash.InternalChart>({
      id: 1,
      //title:{text:""} as Dash.Title,
      displayName: "",
      title: "",
      chartTypeId: 0,
      description: "",
      isAutoScale: true,
      min: 0,
      max: 0,
      dashboardId: 0,
      displayOrder: 0,
      chartType: {} as Dash.ChartType,
      series: [],
      width: 0,
      chartDataRequest: undefined,
      maxPoints:100,
      commands:[],
    });

    const validationSchema = Yup.object().shape({
      title: Yup.string().required().label("Name"),
      chartTypeId: Yup.number().min(1).required().label("Type"),
      isAutoScale: Yup.bool().label("Autoscale"),
      min: Yup.number().label("Minimum"),
      max: Yup.number().label("Maximun"),
      width: Yup.number().required().label("Width"),
    });

    const getChartById = (id) => {
      ApiService.post("/dashboard/chartget", {
        chartId: id,
        unit: "Hour",
        lastPoints: 1,
      } as any)
        .then(({ data }) => {
          console.log("Chartget response data:"); //todo geert
          console.log({ ...data }); //todo geert
          wholeChartData = data;
          newChartData.value = data.internalChart as Dash.InternalChart;

          console.log(newChartData.value);
        })
        .catch(({ response }) => {
          console.log("Fout getChartById");
        });
    };

    if (props.chartId != "0") {
      getChartById(props.chartId);
      console.log("newChartData is nu:");
      console.log(newChartData.value);
    }

    const submit = () => {
      console.log("NewChartdata");
      console.log(newChartData.value);
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button and busy indicator
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.post("/dashboard/chartupdate", {
        internalChart: newChartData.value,
      } as any)
        .then(({ data }) => {
          console.log("submit Chart response data:"); //todo geert
          console.log({ ...data }); //todo geert
          router.push({ name: "dashboard" });
        })
        .catch(({ response }) => {
          console.log("Fout ChartUpdate");
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const clickCancel = () => {
      router.go(-1);
    };

    return {
      newChartData,
      validationSchema,
      submit,
      submitButtonRef,
      newAddressModalRef,
      clickCancel,
      t,
    };
  },
});
